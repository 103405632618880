
import dialogShell from '@/components/common/dialog-shell/index.vue';
import {
    defineComponent, PropType, reactive, Ref, ref, watch
} from 'vue';
import { libraryType } from '@/views/pm/library/util';
import { access } from '@/methods/rule';
import HttpRequest from '@/util/axios.config';
import { community } from '@/data';
import pinEncryptInput from '@/components/common/pin-encrypt-input/index.vue';
import { resident, delivery } from '@/data/src/access';
import { LibraryForm } from './all.type';

export default defineComponent({
    components: {
        dialogShell,
        pinEncryptInput
    },
    props: {
        initForm: {
            type: Object as PropType<LibraryForm>,
            required: true
        },
        // 编辑弹窗标题 标志位
        pinOrCard: {
            type: String as PropType<libraryType>,
            required: true
        }
    },
    setup(props, { emit }) {
        const pinForm: Ref<any> = ref(null);

        const formData: LibraryForm = reactive({ ...props.initForm });

        const identityType = ref(props.pinOrCard);

        const close = () => {
            emit('close');
        };

        const encrypt = props.initForm.Code === '****';
        const submit = () => {
            pinForm.value.validate((valid: boolean) => {
                if (!valid) return false;
                const editUrl = identityType.value === 'pin' ? `v3/web/${community.ProjectType.value}/key/editPinLibrary`
                    : `v3/web/${community.ProjectType.value}/key/editRfCardLibrary`;
                HttpRequest.post(editUrl, {
                    ID: formData.ID,
                    Type: formData.Type,
                    Code: formData.Code
                }, () => {
                    emit('success');
                    close();
                });
                return true;
            });
        };

        const rules = {
            Code: [
                {
                    required: !(identityType.value === 'pin' && community.IsEncryptPin.value === '1'),
                    message: WordList.RuleKeyInvalid,
                    trigger: 'blur'
                },
                {
                    validator: identityType.value === 'pin'
                        ? access.checkAllTypeOfPin(encrypt, 'edit') : access.checkCard,
                    trigger: 'blur'
                }
            ]
        };

        return {
            formData,
            close,
            submit,
            pinForm,
            rules,
            identityType
        };
    }
});

