
import {
    computed, defineComponent, ref, PropType, watch, Ref
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import { propertyBaseRouter } from '@/router/data';
import {
    ListHeaderItem, listPagination, ActionType, RequestData,
    SearchKey,
    ListActionItem
} from '@/components/common/list';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import {
    getMenus, libraryType, changePath, getLibraryPageData, IdentityType
} from '@/views/pm/library/util';
import { LibraryForm } from '@/components/view/pm/library-edit-dialog/all.type';
import libraryEditDialog from '@/components/view/pm/library-edit-dialog/index.vue';
import tip from '@/components/common/tip';
import { resident, delivery, staff } from '@/data/src/access';
import { community, account } from '@/data';
import init from '@/methods/building-apt-choose';
import remove from '@/methods/remove-func';
import { communityForPmApi } from '@/api';

export default defineComponent({
    components: {
        propertyBreadCrumb,
        secondaryNav,
        listPagination,
        libraryEditDialog,
        tip
    },
    props: {
        type: {
            type: String as PropType<libraryType>,
            default: 'pin'
        }
    },
    setup(props) {
        // 增加办公、社区标志位
        const projectType = community.ProjectType;
        const breadCrumb = [{ label: WordList.ProperAllTextLibrary }];

        const secondMenus = getMenus(propertyBaseRouter);

        const { buildOptions, aptOptions, setRoomOption } = init();

        // list所需数据
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });

        const defaultAction: Ref<Array<ActionType>> = ref([]);
        const deleteUrl = ref('');
        const updateToList = ref(0);
        const saveParamInPath = ref(false);
        const searchList = () => {
            updateToList.value += 1;
            saveParamInPath.value = true;
        };

        // 身份选择
        const identity: Ref< IdentityType[] > = ref([]);
        const searchKeyList: Ref< SearchKey > = ref([]);

        // 6.5.1 需求：无归属卡处理
        const cardAction = ref<ListActionItem[]>([{
            type: 'edit2',
            event: 'edit',
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify,
            showCondition(val: { IsShowAction: number; IsCreateByPm: '0' | '1' }) {
                if (activeNavItem.value === 'rfCard') {
                    return val.IsShowAction === 1 && val.IsCreateByPm === '1';
                }
                return val.IsShowAction === 1;
            }
        }, {
            type: 'delete2',
            event(data: { ID: string }) {
                remove(WordList.TabelDeleteText, () => {
                    communityForPmApi[activeNavItem.value === 'rfCard' ? 'deleteCard' : 'deleteppincode']({
                        ID: data.ID
                    }, () => {
                        updateToList.value += 1;
                    });
                });
            },
            class: 'el-icon-delete delete-icon',
            title: WordList.TabelConfigInHtmlDelete,
            showCondition(val: { IsCreateByPm: '0' | '1' }) {
                if (activeNavItem.value === 'rfCard') {
                    return val.IsCreateByPm === '1';
                }
                return true;
            }
        }]);

        const activeNavItem = computed(() => props.type);
        watch([activeNavItem, projectType, community.IsEncryptPin], () => {
            // 切换重置保存搜索项
            saveParamInPath.value = false;
            const pageData = getLibraryPageData(projectType.value)[activeNavItem.value];
            headers.value = pageData.header;
            listRequestData.value = pageData.requestData;
            defaultAction.value = pageData.defaultAction;
            deleteUrl.value = pageData.deleteUrl;
            identity.value = pageData.IdentityOptions;
            searchKeyList.value = pageData.SearchKeyList;
            if (projectType.value === 'office') {
                cardAction.value = [];
                defaultAction.value = ['edit', 'delete'];
            }
        }, {
            immediate: true
        });

        // 监听楼栋选择变化
        watch(computed(() => listRequestData.value.param.Building), (val) => {
            if (val === 'all') setRoomOption();
            else setRoomOption(val as string);
        });

        const isShowLibraryEditDialog = ref(false);
        // 编辑信息
        const initForm: Ref<LibraryForm> = ref({
            Code: '',
            ID: '',
            Type: ''
        });

        const edit = (listItem: LibraryForm) => {
            initForm.value = listItem;
            isShowLibraryEditDialog.value = true;
        };

        // 非PM创建的 RFCard 批量删除时不可勾选
        const disableCheckCondition = (val: { IsCreateByPm: '0' | '1' }) => {
            if (activeNavItem.value === 'rfCard') {
                return val.IsCreateByPm === '0';
            }
            return false;
        };

        return {
            breadCrumb,
            secondMenus,
            activeNavItem,
            changePath,
            listRequestData,
            updateToList,
            defaultAction,
            deleteUrl,
            headers,
            identity,
            searchKeyList,
            edit,
            isShowLibraryEditDialog,
            initForm,
            resident,
            delivery,
            staff,
            buildOptions,
            aptOptions,
            saveParamInPath,
            searchList,
            projectType,
            account,
            cardAction,
            disableCheckCondition
        };
    }
});
