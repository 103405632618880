import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pin_encrypt_input = _resolveComponent("pin-encrypt-input")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_dialog_shell, {
      title: _ctx.identityType=='pin'?_ctx.$wordList.ProperAllTextEditPin:_ctx.$wordList.DeliveryEditRCard,
      onClose: _ctx.close,
      onSubmit: _ctx.submit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          "label-position": _ctx.$formLabelPosition,
          rules: _ctx.rules,
          model: _ctx.formData,
          ref: "pinForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.identityType=='pin'?_ctx.$wordList.ProperAllTextPIN:_ctx.$wordList.DeliveryRfCard,
              prop: "Code"
            }, {
              default: _withCtx(() => [
                (_ctx.identityType === 'pin')
                  ? (_openBlock(), _createBlock(_component_pin_encrypt_input, {
                      key: 0,
                      modelValue: _ctx.formData.Code,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.Code = $event)),
                      option: "edit"
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true),
                (_ctx.identityType === 'rfCard')
                  ? (_openBlock(), _createBlock(_component_el_input, {
                      key: 1,
                      modelValue: _ctx.formData.Code,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.Code = $event))
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["label-position", "rules", "model"])
      ]),
      _: 1
    }, 8, ["title", "onClose", "onSubmit"])
  ]))
}