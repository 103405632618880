import {
    ActionType, ListHeaderItem, RequestData, getSearch,
    SearchKey
} from '@/components/common/list';
import router from '@/router/index';
import { community } from '@/data';

// 标签页
function getMenus(basicRouter: string) {
    return [{
        label: WordList.ProperAllTextPIN,
        key: 'pin',
        path: `/${basicRouter}/library?type=pin`
    }, {
        label: WordList.DeliveryRfCard,
        key: 'rfCard',
        path: `/${basicRouter}/library?type=rfCard`
    }];
}

type libraryType = 'pin' | 'rfCard';

// list header数据
const pinHeader: Array<ListHeaderItem> = [
    {
        label: WordList.ProperAllTextPIN,
        name: 'Code'
    }, {
        label: WordList.TabelPersonDeviceInHtmlOwner,
        name: 'Name',
        type: 'customize'
    }, {
        label: WordList.DeviceDetailDetailUnitName,
        name: 'UnitName'
    }, {
        label: WordList.RDeviceSearchLabelRoomName,
        name: 'RoomName'
    }, {
        label: WordList.TabelMessageBoxCreateTime,
        name: 'CreateTime'
    }
];
const officePinHeader: Array<ListHeaderItem> = [
    {
        label: WordList.ProperAllTextPIN,
        name: 'Code'
    }, {
        label: WordList.TabelPersonDeviceInHtmlOwner,
        name: 'Name',
        type: 'customize'
    }, {
        label: WordList.ProperAllTextDepartment,
        name: 'UnitName'
    }, {
        label: WordList.TabelMessageBoxCreateTime,
        name: 'CreateTime'
    }
];

const rfCardHeader: Array<ListHeaderItem> = [
    {
        label: WordList.DeliveryRfCard,
        name: 'Code'
    }, {
        label: WordList.TabelPersonDeviceInHtmlOwner,
        name: 'Name',
        type: 'customize'
    }, {
        label: WordList.DeviceDetailDetailUnitName,
        name: 'UnitName'
    }, {
        label: WordList.RDeviceSearchLabelRoomName,
        name: 'RoomName'
    }, {
        label: WordList.TabelMessageBoxCreateTime,
        name: 'CreateTime'
    }
];

const officeRfCardHeader: Array<ListHeaderItem> = [
    {
        label: WordList.DeliveryRfCard,
        name: 'Code'
    }, {
        label: WordList.TabelPersonDeviceInHtmlOwner,
        name: 'Name',
        type: 'customize'
    }, {
        label: WordList.ProperAllTextDepartment,
        name: 'UnitName'
    }, {
        label: WordList.TabelMessageBoxCreateTime,
        name: 'CreateTime'
    }
];

// pin、rfcard身份选择区分
interface IdentityType {
    ID: string;
    Label: string;
}
const identityForPin: Array<IdentityType> = [{
    ID: '0',
    Label: WordList.ProperAllTextResident
}, {
    ID: '1',
    Label: WordList.ProperAllTextDelivery
}];
const identityForRfcard: Array<IdentityType> = [{
    ID: '0',
    Label: WordList.ProperAllTextResident
}, {
    ID: '1',
    Label: WordList.ProperAllTextDelivery
}, {
    ID: '2',
    Label: WordList.OfficeStaff
}];
const identityForOffice: Array<IdentityType> = [{
    ID: '30',
    Label: WordList.BasicUser
}, {
    ID: '31',
    Label: WordList.AdvancedUser
}];

// pin、rfcard搜索选择区分
const searchKeyListForEncrypt = [{
    label: WordList.TabelPersonUserInHtmlName,
    name: 'Name'
}];
const searchKeyListForPin = [{
    label: WordList.ProperAllTextPIN,
    name: 'PIN'
}, {
    label: WordList.TabelPersonUserInHtmlName,
    name: 'Name'
}];
const searchKeyListForRfcard = [{
    label: WordList.DeliveryRfCard,
    name: 'RF Card'
}, {
    label: WordList.TabelPersonUserInHtmlName,
    name: 'Name'
}];

// 类型分类
type LibraryPageData = {
    [key in libraryType]: {
        header: Array<ListHeaderItem>;
        requestData: RequestData;
        defaultAction: Array<ActionType>;
        deleteUrl: string;
        IdentityOptions: Array<IdentityType>;
        SearchKeyList: SearchKey;
    }
}
const getLibraryPageData = (projectType: ProjectType | false = false) => {
    const pinRequestData: RequestData = {
        url: `v3/web/${community.ProjectType.value}/key/getPinLibraryList`,
        param: getSearch({
            Building: 'all',
            Apt: 'all',
            Type: 'all',
            searchKey: community.IsEncryptPin.value === '1' ? 'Name' : 'PIN',
            searchValue: ''
        })
    };
    const rfCardRequestData: RequestData = {
        url: `v3/web/${community.ProjectType.value}/key/getRfCardLibraryList`,
        param: getSearch({
            Building: 'all',
            Apt: 'all',
            Type: 'all',
            searchKey: 'RF Card',
            searchValue: ''
        })
    };
    const libraryPageData: LibraryPageData = {
        pin: {
            header: projectType === 'office' ? officePinHeader : pinHeader,
            requestData: pinRequestData,
            defaultAction: [],
            deleteUrl: `v3/web/${community.ProjectType.value}/key/delPinLibrary`,
            IdentityOptions: projectType === 'office' ? identityForOffice : identityForPin,
            SearchKeyList: community.IsEncryptPin.value === '1' ? searchKeyListForEncrypt : searchKeyListForPin
        },
        rfCard: {
            header: projectType === 'office' ? officeRfCardHeader : rfCardHeader,
            requestData: rfCardRequestData,
            defaultAction: [],
            deleteUrl: `v3/web/${community.ProjectType.value}/key/delRfCardLibrary`,
            IdentityOptions: projectType === 'office' ? identityForOffice : identityForRfcard,
            SearchKeyList: searchKeyListForRfcard
        }
    };
    return libraryPageData;
};

const changePath = (key: string, path: string) => {
    router.push(path);
};

export {
    getMenus,
    changePath,
    libraryType,
    getLibraryPageData,
    IdentityType
};
